"use strict";

$(document).ready(function () {
    var screenSm = 768;
    var localNavOffset = $(".local-navigation").outerHeight(true);
    if (window.innerWidth <= screenSm) {
        localNavOffset = 0;
    }

    var localNavigationButtons = $(".local-navigation button");

    function onScroll() {
        var scrollPos = $(document).scrollTop();

        localNavigationButtons.each(function () {
            var currLink = $(this);
            var refElement = $(currLink.attr("value"));
            if (refElement.position().top - 1 - localNavOffset <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                localNavigationButtons.removeClass("current-location");
                currLink.addClass("current-location");
            } else {
                currLink.removeClass("current-location");
            }
        });
    }

    $(document).on("scroll", onScroll);

    if (window.innerWidth > screenSm) {
        $(".local-navigation").stalker({ stalkerStyle: "fixedPosition" });
    }

    //smoothscroll
    $('.local-navigation button[value^="#"]').on("click", function (e) {
        e.preventDefault();
        $(this).blur();
        $(document).off("scroll");

        $("button").each(function () {
            $(this).removeClass("current-location");
        });

        $(this).addClass("current-location");

        var target = this.getAttribute("value");

        target = $(target);
        $("html, body").stop().animate({
            scrollTop: target.offset().top - localNavOffset
        }, 500, "swing", function () {
            $(document).on("scroll", onScroll);
        });
        // The wagtail blocks to which local-navigation buttons scroll may
        // contain links, buttons, and embedded videos (iframes). Find the
        // first of any such focusable elements and send the keyboard focus
        // to it.
        target.find("a[href], div, button, iframe")[0].focus();
    });
});