"use strict";

window.addEventListener("load", function () {
    var params = new URLSearchParams(window.location.search);

    var currentFontFamily = window.getComputedStyle(document.body).fontFamily;

    if (params.has("newFont")) {
        addNewFont(currentFontFamily);
    }

    if (params.has("oldFont")) {
        removeNewFont(currentFontFamily);
    }
});

var newFontName = "Jeko";
var oldFontName = '"Open Sans"';
function toggleNewFont() {
    var currentFontFamily = window.getComputedStyle(document.body).fontFamily;
    if (currentFontFamily.includes(newFontName)) {
        currentFontFamily = currentFontFamily.replace(newFontName, oldFontName);
    } else {
        currentFontFamily = currentFontFamily.replace(oldFontName, newFontName);
    }
    document.body.style.fontFamily = currentFontFamily;
}
function addNewFont(currentFontFamily) {
    var newFontFamily = currentFontFamily.replace(oldFontName, newFontName);
    document.body.style.fontFamily = newFontFamily;
}

function removeNewFont(currentFontFamily) {
    var newFontFamily = currentFontFamily.replace(newFontName, oldFontName);
    document.body.style.fontFamily = newFontFamily;
}