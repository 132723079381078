"use strict";

/* These functions help control some of the nav behavior */

$(function () {
    // Mobile nav toggle
    var mainNav = {};
    var mobileNavContainer = $(".nav-mobile__drawer");
    var nav = $("#mainMenu");

    mainNav.el = {
        nav: nav,
        mobileToggle: $(".main-menu-toggle-btn"),
        unifiedNav: $(".navbar-unified")
    };

    mainNav.fnc = {
        init: function init() {
            // Mobile hamburger toggle
            mainNav.el.mobileToggle.on("click", function () {
                document.body.classList.toggle("overflow-hidden-mobile");
                mainNav.el.unifiedNav.toggleClass("open");
                mainNav.el.nav.toggleClass("mobile-open");
                mainNav.el.mobileToggle.toggleClass("open");
            });
        }
    };

    mainNav.fnc.init();

    // Mobile nav open/close drawer sublinks
    var mobileLinks = $(".nav-mobile").find(".cms-link.has-children");
    var backBtn = $(".nav-mobile__back-btn > a");
    var subnav = $(".nav-mobile__subnav");

    backBtn.on("click", function () {
        mobileNavContainer.removeClass("open");
    });

    mobileLinks.on("click", function (e) {
        e.preventDefault();
        var cmsLink = $(e.target).closest(".cms-link");
        subnav.html(cmsLink.find(".submenu-content").html());
        mobileNavContainer.addClass("open");
    });

    // Mobile nav open to subnav if active link exists
    var activeLink = $(".nav-mobile__main .submenu-content").find(".sublink.active");
    if (activeLink.length > 0) {
        subnav.html(activeLink.prevObject.html());
        mobileNavContainer.addClass("open");
    }

    // Mega Menu
    var desktopLinks = $(".nav-container").find(".cms-link.has-children");
    var megaMenu = $(".nav-mega-menu");
    var megaMenuContent = $(".nav-mega-menu__main");

    var clearActiveLinks = function clearActiveLinks() {
        setTimeout(function () {
            if (!nav.hasClass("open")) {
                desktopLinks.removeClass("active-menu");
            }
        }, 50);
    };

    desktopLinks.hover(function (e) {
        desktopLinks.removeClass("active-menu");
        var cmsLink = $(e.target).closest(".cms-link");
        megaMenuContent.html(cmsLink.find(".submenu-content").html());
        cmsLink.addClass("active-menu");
        nav.addClass("open");
    }, function () {
        nav.removeClass("open");
        clearActiveLinks();
    });

    megaMenu.hover(function (e) {
        nav.addClass("open");
    }, function (e) {
        nav.removeClass("open");
        clearActiveLinks();
    });
});

function toggleSearchBox() {
    // Replace the google custom search placeholder attr
    // the google script changes the placeholder multiple
    // places we need to address them.
    var placeholder = $("input[name='placeholder']").val();
    var searchInput = $("#search-box");

    // we are hiding the language chooser  element in order
    // to match the current designs
    var languageSelector = $(".language-chooser");
    var setPlaceholder = function setPlaceholder(e) {
        if (e) e.preventDefault();
        searchInput.attr("placeholder", placeholder);
    };
    searchInput.on("focus blur click", setPlaceholder);

    var x = document.getElementById("searchBox");
    if (x.classList.contains("active")) {
        x.classList.remove("active");
        languageSelector.show();
    } else {
        languageSelector.hide();
        x.classList.add("active");
        // the custom gsearch js changes the placeholder as well,
        // we need to set it again with a delay to be able to override it.
        setTimeout(function () {
            searchInput.focus();
            setPlaceholder();
        }, 300);
    }
}