"use strict";

$(function () {
    var initSlideshow, initMobileSlideshow;

    initSlideshow = function initSlideshow(selector) {
        var initWallop;
        var wallopSliderElements = document.querySelectorAll(selector);

        initWallop = function initWallop(wallopEl, swipeSelector) {
            var slider = new Wallop(wallopEl);
            var pagination = wallopEl.querySelectorAll(".Wallop-pagination a");

            wallopEl.classList.add("initialized");

            /* Use Observer for dynamic slider content
             * Listen to changes made to the slider list to add/remove pagination dots and reset slider
             */
            var target = wallopEl.querySelectorAll(".Wallop-list")[0];

            var observer = new MutationObserver(function (mutations, observer) {
                var itemsAdded = false;
                var itemsRemoved = false;

                mutations.forEach(function (mutation) {
                    if (mutation.addedNodes.length > 0) {
                        itemsAdded = true;
                    }
                    if (mutation.removedNodes.length > 0) {
                        itemsRemoved = true;
                    }
                });

                if (itemsAdded || itemsRemoved) {
                    slider.reset();
                    pagination = generatePagination(wallopEl, slider, true);
                    if (itemsAdded) {
                        slider.goTo(slider.lastItemIndex, null, true);
                    } else if (itemsRemoved) {
                        slider.goTo(0, null, true);
                    }
                }
            });

            observer.observe(target, {
                childList: true
            });

            // pagination dots
            pagination = generatePagination(wallopEl, slider, false);

            // swipe support using https://github.com/tri613/swipe-it#readme
            var swipeManager = new SwipeIt(swipeSelector);

            swipeManager.on("swipeLeft", function () {
                if (pagination.length > 0) {
                    slider.next();
                }
            });
            swipeManager.on("swipeRight", function () {
                if (pagination.length > 0) {
                    slider.previous();
                }
            });

            // Update dots on change
            slider.on("change", function (e) {
                var activePagination = wallopEl.querySelector(".Wallop-pagination .active");
                var pagination = wallopEl.querySelectorAll(".Wallop-pagination a");
                if (activePagination) {
                    activePagination.classList.remove("active");
                    pagination[e.detail.currentItemIndex].parentNode.classList.add("active");

                    // Set the height on the wrapper so we can animate the height property
                    var height = wallopEl.querySelectorAll(".Wallop-item--current")[0].scrollHeight;
                    wallopEl.querySelectorAll(".Wallop-list")[0].style.height = height + 16 + "px";
                }
            });
        };

        var generatePagination = function generatePagination(wallopEl, slider, rerender) {
            var pagination = wallopEl.querySelectorAll(".Wallop-pagination a");
            var nextPrevButtons = wallopEl.querySelectorAll(".wallop-next-prev");

            // pagination dots
            var toggleSlide = function toggleSlide(e) {
                e.preventDefault();
                var trigger = e.currentTarget;
                var index = pagination.indexOf(trigger);

                pagination.forEach(function (dotEl) {
                    dotEl.parentNode.classList.remove("active");
                });
                trigger.parentNode.classList.add("active");
                slider.goTo(index);
            };

            var slides = wallopEl.querySelectorAll(".Wallop-list .Wallop-item");

            if (pagination.length < 1 || rerender) {
                var slide_num = slides.length;
                var paginationHTML = "";
                var paginationContainer = wallopEl.querySelector(".Wallop-pagination");

                if (slides.length > 1) {
                    for (var i = 0; i < slide_num; i++) {
                        if (i == 0) {
                            paginationHTML += '<div class="active"><a href="#"><span>Slide ' + i + "</span></a></div>";
                        } else {
                            paginationHTML += '<div><a href="#"><span>Slide ' + i + "</span></a></div>";
                        }
                    }
                }
                paginationContainer.innerHTML = paginationHTML;
                pagination = wallopEl.querySelectorAll(".Wallop-pagination a");
            }

            if (nextPrevButtons && nextPrevButtons.length > 0) {
                // show the next/previous buttons if there are more than 1 slide
                if (slides.length > 1) {
                    nextPrevButtons[0].style.removeProperty("display");
                } else {
                    nextPrevButtons[0].style.display = "none";
                }
            }

            pagination = Array.prototype.slice.call(pagination);

            pagination.forEach(function (dotEl) {
                dotEl.addEventListener("click", toggleSlide);
            });
            return pagination;
        };

        for (var i = 0; i < wallopSliderElements.length; i++) {
            var swipeSelector;

            if (wallopSliderElements[i].getAttribute("id") == null) {
                wallopSliderElements[i].setAttribute("id", "icon_wallop" + i);
            }
            swipeSelector = "#" + wallopSliderElements[i].getAttribute("id");
            initWallop(wallopSliderElements[i], swipeSelector);
        }
    };

    initMobileSlideshow = function initMobileSlideshow(selector, breakpoint) {
        var clientWidth = document.documentElement.clientWidth;
        var mobileSliderElements = document.querySelectorAll(selector);

        if (breakpoint >= clientWidth) {
            initSlideshow(selector);
        }
    };

    initSlideshow(".Wallop");

    initMobileSlideshow(".Wallop-mobile", 768);

    window.initSlideshow = initSlideshow;
});