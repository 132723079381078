"use strict";

/* These functions help control some of the nav behavior
Note: some of these functions may not do much of anything at all or have variables that aren't
used, such as triggerTarget. We should clean this up at some point, and ideally see which of these
functions can effectively be replaced with CSS where possible, remove code that is never called,
and do any other refactoring needed
*/

$(function () {
    var mainNav = {};

    mainNav.el = {
        nav: $("#mainMenu"),
        mobileToggle: $(".main-menu-toggle-btn"),
        unifiedNav: $(".navbar-unified")
    };

    mainNav.fnc = {
        init: function init() {
            // Mobile hamburger toggle
            mainNav.el.mobileToggle.on("click", function () {
                document.body.classList.toggle("overflow-hidden-mobile");
                mainNav.el.unifiedNav.toggleClass("open");
                mainNav.el.nav.toggleClass("open");
                mainNav.el.mobileToggle.toggleClass("open");
            });
        }
    };

    mainNav.fnc.init();
});

function toggleSearchBox() {
    // Replace the google custom search placeholder attr
    // the google script changes the placeholder multiple
    // places we need to address them.
    var placeholder = $("input[name='placeholder']").val();
    var searchInput = $("#search-box");

    // we are hiding the language chooser  element in order
    // to match the current designs
    var languageSelector = $(".language-chooser");
    var setPlaceholder = function setPlaceholder(e) {
        if (e) e.preventDefault();
        searchInput.attr("placeholder", placeholder);
    };
    searchInput.on("focus blur click", setPlaceholder);

    var x = document.getElementById("searchBox");
    if (x.classList.contains("active")) {
        x.classList.remove("active");
        languageSelector.show();
    } else {
        languageSelector.hide();
        x.classList.add("active");
        // the custom gsearch js changes the placeholder as well,
        // we need to set it again with a delay to be able to override it.
        setTimeout(function () {
            searchInput.focus();
            setPlaceholder();
        }, 300);
    }
}