"use strict";

$(function () {
    var tooltips = document.querySelectorAll(".tooltip");
    var tooltipArr = new Array();
    for (var i = 0; i < tooltips.length; i++) {
        tooltipArr.push(tooltips[i]);
    }

    tippy(tooltipArr);
});